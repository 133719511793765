import type { Countries } from '../../i18n/i18n.language-config';
import type { ProductType } from '../../types/products/index';

export enum SUBMISSION_SOURCE {
  API = 'API',
  BROKER_PORTAL = 'BROKER_PORTAL',
  EMAIL = 'EMAIL',
}

export enum SubmissionStatus {
  INCOMPLETE = 'INCOMPLETE',
  PROCESSED = 'PROCESSED',
  DECLINED = 'DECLINED',
  LOST = 'LOST',
  EXPIRED = 'EXPIRED',
}

export type SubmissionDetailsDto = {
  agencyAccount?: AgencyAccount;
  assignee: string | null;
  created: string;
  dynamicFields: Record<string, unknown>;
  declinationReasons: string | null;
  expiryDate: string | null;
  firmographics: SubmissionFirmographics;
  id: string;
  industryClass: number;
  metaData: MetaData;
  modified: string;
  needBy: string | null;
  operationalStatus: string;
  productLine: ProductLine;
  submissionNumber: string;
  submissionSource: SUBMISSION_SOURCE;
  submissionStatus: string;
  viable: boolean;
  profileScore: number;
};

export type SubmissionFirmographics = {
  additionalNamedInsured: string[];
  address: USAddress;
  changeInScope: boolean | null;
  changeInScopeDetail?: string;
  dbaOrTradestyle: string | null;
  description: string;
  domainName: string;
  domesticRevenue: number;
  dunsNumber: string;
  ein: string | null;
  entityType: string;
  foreignRevenue: number | null;
  franchiseSystemConnectivity: boolean | null;
  isFranchise: boolean;
  mailingAddress: USAddress;
  naicsCode: string;
  name: string;
  noOfEmployeesAll: number;
  numberOfPersonalRecords: number | null;
  otherDomains: string[];
  ownershipType: string;
  phoneNumber: string;
  physicalAddress: USAddress;
  policyContact: PolicyContact | null;
  primaryIndustry: string;
  primaryNaicsPercentage: number;
  secondaryIndustry: string;
  secondNaicsCode?: number;
  secondPrimaryIndustry?: string;
  secondSecondaryIndustry?: string;
  securityContact: SecurityContact | null;
  sicCode?: string;
  sicPrimaryDesc?: string;
  sicSecondaryDesc?: string;
  totalRevenue: number;
  url: string | null;
  yearEstablished: number;
  industryClass: number;
};

type PolicyContact = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

type SecurityContact = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  company: string;
};

export type SubmissionTableRow = {
  id: string;
  created: string;
  modified: string;
  submissionNumber: string;
  agentId: string;
  /**
   * @deprecated for productLine
   */
  lineOfBusiness: string;

  productLine: ProductLine;

  firmographics: Firmographics;
  agencyAccount?: AgencyAccount;
  submissionSource: SUBMISSION_SOURCE;
  operationalStatus: string;
  submissionStatus: SubmissionStatus;
  assignee: string;
  needBy: string | null;
  declinationReasons: string[] | null;
  viable: boolean;
  msgId: string | null;
};

type AgencyAccount = {
  created: string;
  modified: string;

  agencyName: string;
  agencyId: string;
  accountId: string;
  optOutRetreads: boolean;
  agent: Agent;
};

type Agent = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  team: { teamId: string; teamName: string };
};

type USAddress = {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  country: Countries;
  state: string;
  zipCode: string;
};

type Firmographics = Record<string, unknown> & {
  address: USAddress;
  physicalAddress: USAddress;
};

type ProductLine = {
  created: string;
  id: string;
  key: string;
  modified: string;
  name: string;
  prefix: string;
};

export type CreateSubmissionPayload = {
  cbid?: string;
  firmographics: Firmographics;
  lineOfBusiness?: string;

  agency?: {
    agencyId?: string;
    agencyName?: string;

    agent: {
      firstName?: string;
      lastName?: string;
      emailAddress?: string;
      phoneNumber?: string;
      teams?: Record<string, unknown>[];
    };
  };
};

export interface ISubmissionMetricsResponse {
  totalSubmissions: number;
  renewals: number;
  totalQuotedPremium: number;
  avgQuotedPremium: number;
  declined: number;
  lost: number;
}

export type SubmissionEligibleProductsResponse = {
  eligibleProducts: EligibleProduct[];
};

export type EligibleProduct = {
  productType: ProductType;
  productLine: ProductLine;
};

export type DeclineSubmissionPayload = {
  declinationReason: string;
  declinationReasonDescription: string;
};

type MetaData = {
  systemDefaults?: SystemDefaults;
  agencyMatch?: AgencyMatch;
  cbids?: string[];
  name?: string | null;
  state?: string | null;
};

type SystemDefaults = {
  additionalNamedInsuredDefaulted: boolean;
  changeInScopeDefaulted: boolean;
  domesticRevenueDefaulted: boolean;
  entityTypeDefaulted: boolean;
  isFranchiseDefaulted: boolean;
  naicsCodeDefaulted: boolean;
  noOfEmployeesAllDefaulted: boolean;
  numberOfPersonalRecordsDefaulted: boolean;
  ownershipTypeDefaulted: boolean;
  securityContactDefaulted: boolean;
  yearEstablishedDefaulted: boolean;
};

type AgencyMatch = {
  agencyId: string | null;
  agencyName: string | null;
  agentId: string | null;
  agentFirstName: string | null;
  agentLastName: string | null;
  agentEmail: string | null;
  agentPhone: string | null;
  teamId: string | null;
  teamName: string | null;
};
